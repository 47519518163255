body,
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
}
.App {
  text-align: left;
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #ffffff;
  font-family: "Be Vietnam Pro", Helvetica, Arial, sans-serif;
  text-align: left;
  padding: 0;
  margin: 0;  
}
iframe {
  padding: 0;
  margin: 0;
}
.pageContainer {
  
}
a {
  text-decoration: none;
  color: #000000;
}
a:visited {
  color: black;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Be Vietnam Pro", sans-serif;
}
h1 {
  font-size: 64px;
  letter-spacing: -0.2rem;
  font-weight: 400;
  line-height: 72px;
  margin-top: 0.3em;
}
h2 {
  font-size: 56px;
  letter-spacing: -.05rem;
  font-weight: 400;
}
h3 {
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 0.5em;
  letter-spacing: -.05rem;
}
h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
h5 {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.0em;
  margin-bottom: 0.5em;
}
h6 {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 13px;
  margin: 0;
  color: #8d8f9a;
}
p {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  color: #151617;
}
ul {
  padding: 0;
  list-style: none;
}
.container {
  /*width: 82vw;*/
  margin: 0 auto;
}
.text-padding {
  padding: 15em 0;
}
.subtract-top {
  padding-top: 9em !important;
}
.last-image-padding{
  padding: 0 0 15em 0;
}
.inline-block {
  display: inline-block;
}
.move-up {
  margin-top: 24px;
}
.center {
  margin: 0 auto;
}
.relative {
  position: relative;
}
.pageContainer {
  padding: 244px 0 0 0;
  margin: 0 auto;
  max-width: 82vw;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.navContainer {
  letter-spacing: -0.05rem;
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  font-size: 24px;
  /*background-color: #fff;*/
  width: 100%;
  padding: 24px 0;
  z-index: 1;
}

.logo {
  line-height: 56px;
  display: inline-block;
  padding: 0 48px;
}

.logo a {
  color: #000000;
}
.nav-item {
  line-height: 56px;
  display: inline-block;
}
div.nav-items {
  float: right;
  line-height: 56px;
  padding: 0 48px;
}
.nav-item:first-child {
  padding-right: 24px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
div.About {
  background-color: red;
  width: 100px;
  height: 100px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.intro-textContainer {
  max-width: 82vw;
  margin: 0 auto 64px auto;
  padding: 0 0 72px 0;
}
.intro-text {
  padding: 0 0 4px 0;
  max-width: 800px;
}
.intro-contact {
  max-width: 880px;
  border-top: 1px solid #dbdcdf;
  border-bottom: 1px solid #dbdcdf;
}
.grayText {
  color: #9697A2;
}
/*thumbnail style*/
.thumbnail-container {
  max-width: 82vw;
  /*background-color: #fafafa;*/
  padding: 0 0 56px 0;
  margin: 0 auto 56px auto;
  overflow: hidden;
}
.project-title {
  text-decoration: "none";
  letter-spacing: -0.2rem;
  line-height: 0px !important;
}
.project-subtext {
  text-decoration: "none";
  color: #8d8f9a;
  margin-bottom: 56px;
  margin-top: -8px;
}
.project-subtext h3 {
  line-height: 16px !important;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0rem;
  margin-top: 1em;
}
.project-image-container {
  width: 100%;
  overflow: hidden;
}
.project-image-container img {
  width: 100%;
  margin: auto;
}
a.line-link-sm {
  line-height: 24px;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}
.line-link-sm::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(0, 0, 0);
  transition: width 0.3s;
}

.line-link-sm:hover::after {
  width: 1.2em;
  transition: width 0.3s;
}
a.line-link {
  line-height: 0px;
  display: inline-block;
  text-decoration: none;
}
.line-link::after {
  margin-top: -12px;
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(0, 0, 0);
  transition: width 0.3s;
}

.line-link:hover::after {
  width: 1em;
  transition: width 0.3s;
}
a.line-link-lg {
  line-height: 0px;
  display: inline-block;
  text-decoration: none;
}

.line-link-lg::after {
  content: "";
  display: block;
  margin-top: -10px;
  width: 0;
  height: 4px;
  background: rgb(0, 0, 0);
  transition: width 0.3s;
}

.line-link-lg:hover::after {
  width: 3.5em;
  transition: width 0.3s;
}
.thumbnail-anim img:hover {
  transform: scale(1.1);
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

.thumbnail-anim img:not(:hover) {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.social-container {
  width: 16px;
  height: auto;
  top: 42%;
  right: 0;
  position: fixed;
  z-index: 10;
  padding: 4px 48px;
}
.socialIcon-container {
  width: 100%;
  margin: 16px auto;
}
.social-container-footer {
  width: 50%;
  z-index: 10;
  align-content: center;
  flex-basis: 65%;
}
.social-container-footer .socialIcon-container {
  display: inline-block;
  width: auto;
  height: 20px;
  margin: 2px 8px;
}
.social-container-footer .socialIcon-container:last-child {
  margin-right: 0;
}
.social-container-footer img {
  width: 16px !important;
}
.socialSVG img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.footer-container {
  background-color: #fbfbfb;
  padding: 7% 48px;
  display: flex;
}
.footer-text-container {
  height: 100px;
  width: 35%;
  line-height: 100px;
  display: inline-block;
  flex-basis: 35%;
  
}
.footer-text-container p {
  display: inline-block;
  line-height: 40px;
  font-size: 14px;
}
.footer-text-container p:nth-child(2) {
  float: right;
}
.workContainer {
  width: 100%;
  height: 900px;
  padding: 0 48px;
}
.workHeader-container {
  width: 100%;
  margin-top: 16px;
  /*background-color: rgba(0, 0, 255, .1);*/
}
.workHeader-title {
  text-indent: -5px;
}
.workHeader-title h1 {
  margin-bottom: 2em;
}
.workHeader-meta {
  text-indent: 2px;
}
.workHero-image {
  overflow: hidden;
}

.workHero-image img {
  width: 100%;
  height: auto;
}
.workHero-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.workHero-video-addShadow {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  filter:drop-shadow(0px 14px 40px rgba(0, 0, 0, 0.16));
}

.workHero-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.workHero-video-addShadow iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.descriptionContainer {
  padding: 10em 0;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
}
.resultsContainer {
  padding: 10em 0 2em 0;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
}
.statscontainer {
  padding: 2em 0 10em 0;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
}
.gridContainer {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 4.0rem;
}
.gridCell{
  padding: 2em 0;
  border-bottom: 2px solid #dbdcdf;
  flex-direction: column;
  justify-content: flex-end;
}
.descriptionContainerUp {
  padding: 4em 0 10em 0;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
}
.description-copy h2{
  margin-bottom: 20px;
}
.description-copy p{
font-size: 22px;
line-height: 40px;
}
.detailContainer {
  display: flex;
  padding: 1em 0;
  margin: 0 auto;
  height: auto;
}
.aboutHeaderContainer {
  -webkit-flex: 0 0 32%;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  margin-bottom: 16em;
}
.aboutHeaderContainer h2 {
  margin: 0;
}
.aboutHeaderContainer .side-col-lg {
  padding: 1em 1em 0 0;
  max-width: 37rem !important;
}
.col-sm {
  -webkit-flex-basis: 18%;
  -ms-flex-preferred-size: 18%;
  flex-basis: 18%;
  padding-right: 0.5em;
}
.col-sm-2 {
  -webkit-flex: 0 8vw;
  -ms-flex: 0 8vw;
  flex: 0 0 8vw;
}
.col-sm ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
}
.side-col-lg {
  max-width: 37rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 65%;
  -ms-flex: 0 65%;
  flex: 0 0 65%;
}
.side-image {
  -webkit-flex: 0 0 34%;
  -ms-flex: 0 0 34%;
  flex: 0 0 34%;
}
.side-image img {
  width: 100%;
}
@-webkit-keyframes hue {
  0%,
  100% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(180deg);
  }
}

.hue {
  -webkit-animation: hue 3s infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*XL desktop size*/
@media screen and (min-width: 2000px) {
  h1 {
    font-size: 72px;
    line-height: 80px;
    margin-top: 0.3em;
  }
  .line-link::after {
    margin-top: -10px;
    height: 3px;
  }
  .pageContainer {
    padding: 225px 0 0 0;
  }
  .social-container {
    width: 20px;
    transition: all 0.5s ease-out;
  }
  .social-container-footer img {
    width: 20px !important;
  }
  .intro-text {
    max-width: 880px;
  }
  .logo,
  div.nav-items {
    padding: 0 5.5vw;
  }
  .navContainer{
    font-size: 32px;
  }
  .footer-container {
    padding: 7% 5vw;
  }
}
/*tablet size*/
@media screen and (max-width: 999px) and (min-width: 500px) {
  .mobile-hide {
    display: none;
  }
  .navContainer {
    background-color: white;
  }
  .intro-textContainer {
    margin-bottom: 0in;
  }
  .intro-text {
    padding-top: 48px;
  }
  .thumbnail-container {
    margin-bottom: 0in;
  }
  .pageContainer {
    padding: 120px 24px 0 24px;
  }
  .descriptionContainer {
    padding: 8em 0;
    max-width: 650px;
    margin: 0 auto;
  }
  .resultsContainer {
    padding: 8em 0 4em 0;
    max-width: 650px;
    margin: 0 auto;
  }
   .statscontainer {
    padding: 0 0 10em 0;
  }
  .gridContainer {    
    grid-template-columns: auto;
    grid-gap: 2rem;
  }
  .gridCell{
    padding: 2rem 0;
    border-bottom: 2px solid #dbdcdf;
    flex-direction: column;
    justify-content: flex-end;
  }
  .aboutHeaderContainer .side-col-lg {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    margin-bottom: 8px;
    letter-spacing: -0.1rem;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0px;
    letter-spacing: 0;
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
    max-width: 80%;
    letter-spacing: 0;
  }
  p {
    font-size: 18px;
  }
  .intro-contact {
    margin-top: 16px;
  }
  .intro-contact p{
    line-height: 18px;
  }
  .subtract-top {
    padding-top: 4em !important;
}
  .side-image {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-top: 48px;
  }
  .side-col-lg {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .mobile-scale-img {
    width: 120% !important;
    margin-left: -11% !important;
    margin-top: 6% !important;
  }
  .text-padding {
    padding: 8em 0;
  }
  .last-image-padding{
    padding: 0 0 8em 0;
  }
  .col-sm-2 {
    flex: 0 0 0vw !important;
}
  .col-sm {
    margin-bottom: 2em;
  }
  .col-sm li{
    margin-bottom: 0.4rem;
  }
  .project-subtext {
    margin-bottom: 48px;
  }
}
/*mobile size*/
@media screen and (max-width: 499px) {
  .mobile-hide {
    display: none;
  }
  .navContainer {
    padding: 8px 0;
    font-size: 22px;
    background-color: white;
  }
  .thumbnail-container {
    padding: 0;
    margin: 0 0 40px 0;
    max-width: 100%;
  }
  .intro-textContainer {
    padding: 0 16px;
    margin: 24px 0 24px 0;
    max-width: 100vw;
  }
  .intro-contact{
    max-width: 100vw;
    margin-top: 16px;
  }
  .intro-contact p{
    line-height: 18px;
  }
  .social-container {
    padding: 4px 16px;
  }
  .pageContainer {
    max-width: 100%;
    padding: 100px 0 0 0;
  }
  .descriptionContainer {
    padding: 0;
    display: block;
    margin-bottom: 2em;
  }
  .resultsContainer {
    padding: 2em 0 0 0;
    display: block;
  }
  .statscontainer {
    padding: 0 16px 10em 16px;
  }
  .gridContainer {    
    grid-template-columns: auto;
    grid-gap: 2rem;
  }
  .gridCell{
    padding: 2rem 0;
    border-bottom: 2px solid #dbdcdf;
    flex-direction: column;
    justify-content: flex-end;
  }
  .descriptionContainerUp{
    padding: 4em 0 4em 0;
  }
  .footer-container {
    padding: 7% 24px;
  }
  .footer-text-container {
    line-height: 16px;
    height: 70px;
  }
  .footer-text-container p {
    font-size: 14px;
  }
  .aboutHeaderContainer {
    padding: 0 16px;
    margin-bottom: 32px;
  }
  .subtract-top {
    padding-top: 2em !important;
  }
  .logo,
  div.nav-items {
    padding: 0 16px;
  }
  .intro-text {
    padding-top: 24px;
  }
  .workHeader-title {
    padding: 0 16px;
    text-indent: -1px;
  }
  .workHeader-meta{
    padding: 0 16px;
  }
  .detailContainer{
    padding: 0 16px;
  }
  h1 {
    font-size: 29px;
    line-height: 36px;
    margin-bottom: 8px;
    letter-spacing: -0.1rem;
  }
  .workHeader-title h1 {
    margin-bottom: 1em;
  }
  h2 {
    font-size: 27px;
    line-height: 34px;
    margin-bottom: 16px;
    letter-spacing: -0.04rem;
    font-weight: 400;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
    max-width: 80%;
    letter-spacing: -0.04rem;
    margin-top: 0.5rem !important;
  }
  h5{
    font-size: 13px;
  }
  h6{
    font-size: 11px;
  }
  .description-copy{
    padding: 0 16px;
  }
  .description-copy p {
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  .project-title{
    padding: 0 16px;
  }
  .project-subtext {
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .project-subtext h3{
    font-size: 16px;
    line-height: 8px !important;
  }
  .side-image {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-top: 48px;
  }
  .side-col-lg {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .aboutHeaderContainer .side-col-lg {
    padding-top: 4em;
  }
  .col-sm {
    margin-bottom: 2em;
    flex-basis: 24%;  
  }
  .col-sm li{
    margin-bottom: 0.4rem;
  }
  .col-sm ul{
    font-size: 13px;
    line-height:18px;
  }
  .text-padding {
    padding: 2em 16px;
  }
  .last-image-padding{
    padding: 0 0 4em 0;
  }
  .mobile-scale-img {
    width: 120% !important;
    margin-left: -11% !important;
    margin-top: 6% !important;
  }
}
